<script>
  import appConfig from "@/app.config";
  import store from "@/state/store";
  var state = store.state;
  export default {
    page: {
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    data() {
      return {
        data: {},
        ArchiveFiles: [],
        archive_files_types: [],
        page: 1,
        limit: 20,
        tot_pages: 0,
        searchArchiveFiles: null,
        ArchiveFilesSearchMode: false,
        searchModel: "",
        state: state,
      };
    },
    methods: {
      addArchiveFiles() {
        console.log("**********####", this.state);
        var data = {
          title: "popups.add_marketing_area",
          inputs: [
            {
              model: "name",
              type: "text",
              label: "name",
            },
          ],
          buttons: [
            {
              text: "popups.add",
              closer: true,
              color: "primary",
              handler: (obj) => {
                console.log(obj);
                this.http.post("marketing-areas", obj,null,true).then(() => {
                  this.get(this.page);
                });
              },
            },
            {
              text: "popups.cancel",
              closer: true,
              color: "danger",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.modal(data);
      },
      editArchiveFiles(app) {
        var data = {
          title: "popups.archive_files",
          inputs: [
            {
              model: "name",
              type: "text",
              label: "name",
              value: app.name,
            },
          ],
          buttons: [
            {
              text: "popups.edit",
              closer: true,
              color: "primary",
              handler: (obj) => {
                obj.id = app.id;
                console.log({ obj });
                this.http.post("marketing-areas/update", obj, null, true).then(() => {
                  this.get(this.page);
                });
              },
            },
            {
              text: "popups.cancel",
              closer: true,
              color: "danger",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.modal(data);
      },
      search() {
        this.ArchiveFilesSearchMode = true;
        this.tot_pages = 0;
        this.http
          .post("marketing-areas/search", {
            search: this.searchModel,
            limit: this.limit,
            page: this.page,
            col: "name",
          }, null, true)
          .then((res) => {
            this.ArchiveFiles = res.data;
            console.log("######## files", this.ArchiveFiles);
          });
      },
      cancelappsearchMode() {
        this.searchModel = "";
        this.ArchiveFilesSearchMode = false;
        this.get(this.page);
      },

      getArchiveFiles() {
        this.http.get("marketing-areas",null, null, true).then((res) => {
          this.ArchiveFiles = res.data;
        });
      },
      deleteArchiveFiles(app) {
        var data = {
          title: "popups.deletefile",
          msg: "popups.delmessage",
        };
        this.popup.confirm(data).then((resp) => {
          if (resp) {
            console.log("###");
            this.http
              .post("marketing-areas/delete", { id: app.id })
              .then(() => {
                console.log("############################3");
                this.get(this.page);
              });
          }
        });
      },

      get(page) {
        console.log(page);
        this.http
          .post("marketing-areas/pagination", {
            limit: this.limit,
            page: page,
          }, null, true)
          .then((res) => {
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            console.log({ returnedData: res.data });
            this.ArchiveFiles = res.data;
          });
      },
    },
    created() {
      this.get(1);
      // this.getArchiveFiles();
    },
  };
</script>

<template>
  <div class="row align-items-center">
    <div class="col-4">
      <!-- <div>
        <h5 class="mb-0">
          {{ $t("menu.menuitems.marketing.tabs.marketing_areas") }}
        </h5>
      </div> -->
    </div>

    <div class="col-8">
      <div
        class="search-box chat-search-box d-flex align-items-center"
        style="width: 250px; margin-inline-start: auto"
      >
        <div class="position-relative mx-3 d-flex align-items-center">
          <input
            @keyup="search()"
            type="text"
            class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
            :placeholder="$t('users.searchplaceholder')"
            v-model="searchModel"
            style="background-color: #2a3042 !important"
          />
          <i class="bx bx-search-alt search-icon text-light"></i>
          <button
            @click="cancelappsearchMode()"
            v-if="searchModel"
            class="btn-close text-light"
            style="position: absolute; top: 12px; left: 250px"
          ></button>
        </div>
        <button
          type="button"
          class="btn btn-light float-end"
          @click="addArchiveFiles"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>
  </div>
  <!-- end row -->

  <hr class="mb-4" />

  <div class="row">
    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.id") }}
          </th>
          <th scope="col">
            {{ $t("archiving_categories.archiving_categories_table.name") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.created") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.updated") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.operations") }}
          </th>
          "
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, i) in ArchiveFiles" :key="app" class="text-center">
          <td>{{ i + 1 }}</td>
          <td>{{ app.name }}</td>
          <td>{{ app.created.split("T")[0] }}</td>
          <td>{{ app.updated.split("T")[0] }}</td>

          <td class="d-flex justify-content-end">
            <a
              class="btn btn-primary mx-1"
              @click="editArchiveFiles(app)"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.edit") }}</a
            >
            <a
              class="btn btn-danger"
              href="javascript: void(0);"
              role="button"
              @click="deleteArchiveFiles(app)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <div v-if="tot_pages == 20">
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
</template>

<style scoped>
  .btn-close {
    position: relative;
    right: 317px;
    top: 6px;
    font-size: 15px;
  }
</style>
