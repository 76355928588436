<script>
  import appConfig from "@/app.config";
  // import PageHeader from "@/components/page-header";
  import MarketingSurveyList from "./components/MarketingSurveyList.vue";
  import MarketingTeamList from "./components/MarketingTeamList.vue";
  import MarketingAreaList from "./components/MarketingAreaList.vue";

  export default {
    page: {
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    components: {
      // PageHeader,
      MarketingSurveyList,
      MarketingTeamList,
      MarketingAreaList,
    },
  };
</script>

<template>
    <!-- <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.marketing.subItems.marketing_survey')"
    /> -->
    <div class="row">
      <b-tabs
        content-class="p-4"
        class="pt-2"
        nav-wrapper-class="nav-item"
        nav-class="justify-content-start nav-tabs-custom"
      >
        <!-- survey list -->
        <b-tab :title="$t('menu.menuitems.marketing.tabs.survey_list')" active>
          <marketing-survey-list></marketing-survey-list>
        </b-tab>
        <!-- team members -->
        <b-tab :title="$t('menu.menuitems.marketing.tabs.team_members')">
          <marketing-team-list></marketing-team-list>
        </b-tab>
        <!-- areas -->
        <b-tab :title="$t('menu.menuitems.marketing.tabs.marketing_areas')">
          <marketing-area-list></marketing-area-list>
        </b-tab>
      </b-tabs>
    </div>

    <!-- end  table -->

    <!--   Apps  Pagination     -->
    <ul
      v-if="tot_pages >= 20"
      class="pagination pagination-rounded justify-content-center mb-2"
    >
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->
</template>
